import { template as template_7de9ec5dd8ae4b49b4f9cd940287b25a } from "@ember/template-compiler";
const FKText = template_7de9ec5dd8ae4b49b4f9cd940287b25a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
