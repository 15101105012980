import { template as template_7fcb5a2c6c7443bc9bc51213fa4430af } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_7fcb5a2c6c7443bc9bc51213fa4430af(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
