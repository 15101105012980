import { template as template_7ac4cb9259bd49aca389a849ceb4ae5a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_7ac4cb9259bd49aca389a849ceb4ae5a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
